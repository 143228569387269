<ng-template #waitingAuth>
  <div class="waiting-auth-container">
    <img class="waiting-image" [src]="'../assets/svg/waiting-auth.svg'" alt="waiting-auth-logo">
    <app-custom-spinner></app-custom-spinner>
  </div>
  <div class="container-title">
    <div class="text-big-01">
      Autoriza la transacción en Copec Pay
    </div>
  </div>
  <div class="sale-detail-container">
    <div class="detail-line">
      <div>Comercio</div>
      <div class="detail-text">{{businessName}}</div>
    </div>
    <div class="detail-line">
      <div>Monto</div>
      <div class="detail-text">{{amount}}</div>
    </div>
  </div>
  <div class="button-container">
    <p-button label="Anular" [text]="true" [plain]="true" (onClick)="onCancelSale()"></p-button>
  </div>
</ng-template>
<div *ngIf="isLoading; else mainModal" class="central-container" style="height: 298.5px;">
  <app-custom-spinner></app-custom-spinner>
</div>
<ng-template #mainModal>
  <app-main-modal [templateRef]="waitingAuth" [@fadeInOut]></app-main-modal>
</ng-template>